<template>
    <div class="home">
        <adm-card-list :modules="modules"></adm-card-list>
    </div>
</template>

<script>
// @ is an alias to /src

import AdmCardList from "@/components/AdmCardList";

export default {
    name: 'Home',
    components: {
        AdmCardList
    },
    data() {
        return {
            modules: [
                {
                    name: "PismPlatform",
                    icon: require("@/assets/logo_pism_plat.png"),
                    location: "/pla"
                },
                {
                    name: "Jenkins",
                    icon: require("@/assets/logo_jenkins.svg"),
                    location: "/jenkins"
                },
                {
                    name: "Nacos",
                    icon: require("@/assets/logo_nacos.svg"),
                    location: "https://nacos.pism.com.cn/nacos"
                },
                // {
                //     name: "Nexus",
                //     icon: "https://www.sonatype.com/hubfs/2019%20Product%20logo/Product%20Logo%20SVGs/NexusRepo_Icon.svg",
                //     location: "/nexus"
                // },
                {
                    name: "Jrlic",
                    icon: require("@/assets/logo_jrebel.svg"),
                    location: "/jrlic"
                },
                {
                    name: "BatsLog",
                    icon: require("@/assets/logo_batslog.svg"),
                    location: "https://perccyking.github.io/batslog/"
                },
                {
                    name: "Gitea",
                    icon: 'https://adm.pism.com.cn/gitea/assets/img/logo.svg',
                    location: "/gitea"
                },
                // {
                //     name: "SeaFile",
                //     icon: "https://sf.pism.com.cn/media/img/seafile-logo.png",
                //     location: "https://sf.pism.com.cn/"
                // },
                // {
                //     name: "Plex",
                //     icon: require("@/assets/logo_plex.svg"),
                //     location: "http://34.87.108.120:32400/web/index.html"
                // },
            ]
        }
    }
}
</script>

<style>
</style>
