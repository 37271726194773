<template>
    <div style="padding: 20px;">
        <a-row type="flex" :gutter="[50,16]" justify="center">
            <a-col v-for="(row, index) in modules"
                   :key="index"
                   :span="8" :xs="24" :sm="12" :md="8" :lg="6" :xl="6" :xxl="4">
                <a-card hoverable style="width: 270px;margin: 5px auto;" @click="openTarget(row.location)">
                    <img
                        style="padding: 10px;"
                        height="300px"
                        slot="cover"
                        :alt="row.name"
                        :src="row.icon"
                    />
                    <a-card-meta :title="row.name">
                    </a-card-meta>
                </a-card>
            </a-col>
        </a-row>
    </div>
</template>

<script>
export default {
    props: {
        modules: {
            type: Array
        }
    },
    name: "AdmCardList",
    data() {
        return {
            grid: {
                xs: 1,
                sm: 1,
                md: 1,
                lg: 1,
                xl: 1,
                xxl: 1
            }
        }
    },
    methods:{
        openTarget(src){
            window.open(src)
        }
    }
}
</script>

<style scoped>

</style>
